// Babelを介したpollyfillを有効にする
// https://github.com/rails/webpacker/blob/v4.2.2/docs/es6.md#babel
import "core-js/stable";
import "regenerator-runtime/runtime";

import alertMessage from "src/AlertMessage.js";
import mountReactComponents from "src/react_support.js";
import { CircularProgressbar } from "react-circular-progressbar";

// ReactではないJS実装
import { groupEditFormController } from "src/group_edit_form_controller.ts";

// ES2015で書かれたReactコンポーネントの読み込み
import ActivityLimitExceedanceToggleSwitch from "src/ActivityLimitExceedanceToggleSwitch.jsx";
import { AnnouncementsContainer } from "src/announcements/Container.tsx";
import ApiKeySettingsContainer from "src/api_key_settings/ApiKeySettingsContainer.jsx";
import { DeleteCalendarButton } from "src/calendars/DeleteCalendarButton.tsx";
import EbsBackupExistsTableContainer from "src/ebs_backup_check_results/aws_accounts/EbsBackupExistsTableContainer.jsx";
import EbsBackupNotExistsTableContainer from "src/ebs_backup_check_results/aws_accounts/EbsBackupNotExistsTableContainer.jsx";
import EbsBackupCheckResultsTableContainer from "src/ebs_backup_check_results_table/EbsBackupCheckResultsTableContainer.jsx";
import DuplicateJobButton from "src/job_dashboard/DuplicateJobButton.tsx";
import { GroupAndAwsAccountSelector } from "src/job_dashboard/GroupAndAwsAccountSelector.tsx";
import { GroupAndProviderAccountSelector } from "src/job_dashboard/GroupAndProviderAccountSelector.tsx";
import { GroupAwsAccountTableContainer } from "src/groups/aws_accounts/GroupAwsAccountTableContainer.tsx";
import { GroupGoogleCloudAccountTableContainer } from "src/groups/google_cloud_accounts/GroupGoogleCloudAccountTableContainer.tsx";
import { ProviderAndActionSelector } from "src/job_dashboard/ProviderAndActionSelector.tsx";
import GroupIconName from "src/GroupIconName.jsx";
import GroupIconTip from "src/GroupIconTip.jsx";
import GroupSelector from "src/GroupSelector.jsx";
import TriggerJobFormEcsSecurityGroupsSelector from "src/trigger_job_form/TriggerJobFormEcsSecurityGroupsSelector.tsx";
import TriggerJobFormEcsSubnetsSelector from "src/trigger_job_form/TriggerJobFormEcsSubnetsSelector.tsx";
import TriggerJobFormGroupSelector from "src/trigger_job_form/TriggerJobFormGroupSelector.jsx";
import JobWorkflowEditFormContainer from "src/job_workflow_form/JobWorkflowEditFormContainer.jsx";
import JobWorkflowNewFormContainer from "src/job_workflow_form/JobWorkflowNewFormContainer.jsx";
import ManualLink from "src/ManualLink.jsx";
import { NewGoogleCloudAccountForm } from "src/NewGoogleCloudAccountForm";
import { NewAwsAccountIamRoleForm } from "src/NewAwsAccountIamRoleForm.jsx";
import { NewAwsAccountIamUserForm } from "src/NewAwsAccountIamUserForm.jsx";
import { NewGroupWithGoogleServiceAccountForm } from "src/groups/NewGroupWithGoogleServiceAccountForm.tsx";
import { NewGroupWithIamRoleForm } from "src/groups/NewGroupWithIamRoleForm.tsx";
import { NewGroupWithIamUserForm } from "src/groups/NewGroupWithIamUserForm.tsx";
import OldJobWorkflowEditFormContainer from "src/job_workflow_form/OldJobWorkflowEditFormContainer.jsx";
import OldJobWorkflowNewFormContainer from "../src/job_workflow_form/OldJobWorkflowNewFormContainer";
import PasswordSettingsContainer from "src/password_settings/PasswordSettingsContainer.jsx";
import PolicyLogTable from "src/policy_log_table/PolicyLogTableContainer.jsx";
import PolicySet from "src/policy_set/PolicySet.jsx";
import PolicySetFormContainer from "src/policy_set_form/PolicySetFormContainer.jsx";
import PolicySetTableContainer from "src/PolicySetTableContainer.jsx";
import PostProcessAssignmentsPanel from "src/post_process_assignments_panel/PostProcessAssignmentsPanel.jsx";
import PostProcessAssignmentsPanelContainer from "src/post_process_assignments_panel/PostProcessAssignmentsPanelContainer.jsx";
import PostProcessFormContainer from "src/post_process_form/PostProcessFormContainer.jsx";
import PostProcessPolicySetTableContainer from "src/post_process_policy_sets/PolicySetTableContainer.jsx";
import PostProcessTableContainer from "src/post_process_table/PostProcessTableContainer.jsx";
import PostProcessTriggerJobTableContainer from "src/post_process_trigger_jobs/TriggerJobTableContainer.jsx";
import ProfileImageUploaderContainer from "src/profile_image_uploader/ProfileImageUploaderContainer.jsx";
import ResourceTableContainer from "src/ResourceTableContainer.jsx";
import TFASettingsContainer from "src/tfa_settings/TFASettingsContainer.jsx";
import SupportLink from "src/SupportLink.jsx";
import UsageBoxContainer from "src/UsageBoxContainer.jsx";
import UserGroupsTableContainer from "src/organization/users/groups/UserGroupsTableContainer.jsx";
import UserTableContainer from "src/user_table/UserTableContainer.jsx";
import WebhookPostProcessTestButton from "src/WebhookPostProcessTestButton.jsx";
import WorkSpacesListsContainer from "src/workspaces_lists/WorkSpacesListsContainer.jsx";

import GroupColorSelector from "src/groups/GroupColorSelector.jsx";
import GroupTableContainer from "src/groups/GroupTableContainer.jsx";
import GroupUserTableContainer from "src/groups/users/GroupUserTableContainer.jsx";
import DeleteGroup from "src/groups/DeleteGroup.jsx";
import RecentLogsContainer from "src/recent_logs/RecentLogsContainer.jsx";
import { RunJobButton } from "src/job_dashboard/RunJobButton.tsx";
import { RunJobOnDemandButton } from "src/job_dashboard/RunJobOnDemandButton.tsx";
import { RunJobWorkflowOnDemandButton } from "src/job_dashboard/RunJobWorkflowOnDemandButton.tsx";
import { ToggleJobActiveButtonContainer } from "src/job_dashboard/ToggleJobActiveButtonContainer.tsx";
import DeletePostProcessButton from "src/post_process_table/DeletePostProcessButton.jsx";
import TriggerJobFormAdditionalTagsForm from "src/trigger_job_form/AdditionalTagsForm.jsx";

// ビューテンプレート内の react_component ヘルパーでReactコンポーネントをマウントするには
// スクリプトのトランスパイル時ではなくブラウザでの実行時に各コンポーネントのコンストラクタを
// 参照できる必要があるため、グローバルオブジェクトにコンストラクタを設定しておきます。
window.ActivityLimitExceedanceToggleSwitch = ActivityLimitExceedanceToggleSwitch;
window.AnnouncementsContainer = AnnouncementsContainer;
window.DeleteCalendarButton = DeleteCalendarButton;
window.EbsBackupExistsTableContainer = EbsBackupExistsTableContainer;
window.EbsBackupNotExistsTableContainer = EbsBackupNotExistsTableContainer;
window.DuplicateJobButton = DuplicateJobButton;
window.GroupIconName = GroupIconName;
window.GroupIconTip = GroupIconTip;
window.GroupSelector = GroupSelector;
window.GroupAndAwsAccountSelector = GroupAndAwsAccountSelector;
window.GroupAndProviderAccountSelector = GroupAndProviderAccountSelector;
window.GroupAwsAccountTableContainer = GroupAwsAccountTableContainer;
window.GroupGoogleCloudAccountTableContainer = GroupGoogleCloudAccountTableContainer;
window.ProviderAndActionSelector = ProviderAndActionSelector;
window.TriggerJobFormEcsSecurityGroupsSelector = TriggerJobFormEcsSecurityGroupsSelector;
window.TriggerJobFormEcsSubnetsSelector = TriggerJobFormEcsSubnetsSelector;
window.TriggerJobFormGroupSelector = TriggerJobFormGroupSelector;
window.TriggerJobFormAdditionalTagsForm = TriggerJobFormAdditionalTagsForm;
window.JobWorkflowEditFormContainer = JobWorkflowEditFormContainer;
window.JobWorkflowNewFormContainer = JobWorkflowNewFormContainer;
window.ManualLink = ManualLink;
window.NewGoogleCloudAccountForm = NewGoogleCloudAccountForm;
window.NewAwsAccountIamRoleForm = NewAwsAccountIamRoleForm;
window.NewAwsAccountIamUserForm = NewAwsAccountIamUserForm;
window.NewGroupWithGoogleServiceAccountForm = NewGroupWithGoogleServiceAccountForm;
window.NewGroupWithIamRoleForm = NewGroupWithIamRoleForm;
window.NewGroupWithIamUserForm = NewGroupWithIamUserForm;
window.OldJobWorkflowEditFormContainer = OldJobWorkflowEditFormContainer;
window.OldJobWorkflowNewFormContainer = OldJobWorkflowNewFormContainer;
window.PolicySetTableContainer = PolicySetTableContainer;
window.ProfileImageUploaderContainer = ProfileImageUploaderContainer;
window.ResourceTableContainer = ResourceTableContainer;
window.SupportLink = SupportLink;
window.TFASettingsContainer = TFASettingsContainer;
window.UsageBoxContainer = UsageBoxContainer;
window.UserGroupsTableContainer = UserGroupsTableContainer;
window.UserTableContainer = UserTableContainer;
window.ApiKeySettingsContainer = ApiKeySettingsContainer;

window.GroupColorSelector = GroupColorSelector;
window.GroupTableContainer = GroupTableContainer;
window.GroupUserTableContainer = GroupUserTableContainer;
window.DeleteGroup = DeleteGroup;
window.DeletePostProcessButton = DeletePostProcessButton;

// react-rails の react_component ヘルパーから参照できるようにコンポーネントをグローバルな名前空間に置く
window.EbsBackupCheckResultsTableContainer = EbsBackupCheckResultsTableContainer;
window.PasswordSettingsContainer = PasswordSettingsContainer;
window.PolicyLogTable = PolicyLogTable;
window.PolicySets = {
  FormContainer: PolicySetFormContainer,
  PolicySet: PolicySet,
};
window.PolicySetTableContainer = PolicySetTableContainer;
window.PostProcesses = {
  AssignmentsPanel: PostProcessAssignmentsPanel,
  AssignmentsPanelContainer: PostProcessAssignmentsPanelContainer,
  FormContainer: PostProcessFormContainer,
  TableContainer: PostProcessTableContainer,
};
window.PostProcessPolicySetTableContainer = PostProcessPolicySetTableContainer;
window.PostProcessTriggerJobTableContainer = PostProcessTriggerJobTableContainer;
window.ResourceTableContainer = ResourceTableContainer;
window.RunJobButton = RunJobButton;
window.RunJobOnDemandButton = RunJobOnDemandButton;
window.RunJobWorkflowOnDemandButton = RunJobWorkflowOnDemandButton;
window.ToggleJobActiveButtonContainer = ToggleJobActiveButtonContainer;
window.UsageBoxContainer = UsageBoxContainer;
window.WebhookPostProcessTestButton = WebhookPostProcessTestButton;
window.WorkSpacesListsContainer = WorkSpacesListsContainer;
window.RecentLogsContainer = RecentLogsContainer;
window.CircularProgressbar = CircularProgressbar;

window.jQuery(document).ready(() => {
  mountReactComponents();

  if (window.jQuery("#js-groups-edit-form").length > 0) {
    // グループ編集フォームが存在する場合の処理
    groupEditFormController("js-groups-edit-form");
  }
});

// erbからjsを利用してflashメッセージを表示させるための関数
window.alertMessage = alertMessage;

// 文字列のバイト数ではなく見た目の文字数を返す関数
//
// CoffeeScript内の jQuery.validator に設定するカスタムルールの内部から
// 参照する必要があるため、グローバルな名前空間に定義します。
// 処理内でスプレッド構文を利用する必要がありCoffeeScript内に記述することが
// できないため、ここで記述しています。
//
// 参考: https://qiita.com/suin/items/3da4fb016728c024eaca
window.kanrinmaruCountCharacterLength = (function () {
  // Array.lengthを利用して見た目の文字数を算出する関数を定義して返す。
  //
  // ここで、Intl.Segmenter を使えば絵文字シーケンス(イングランド国旗など)なども
  // 正しく見た目の文字数が算出できるようになる。
  // しかし、ActiveModel(Ruby 2.7 + Rails 6.1)のlengthバリデーションは
  // 絵文字シーケンスの文字数をバイト数で算出するので、フロントエンドとバックエンドで
  // バリデーション結果に齟齬が発生してしまう。
  // そのため、ここではRuby側と互換性のある単純なロジックを採用している。
  return (value) => [...value].length;
})();
